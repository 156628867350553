.content {
    overflow: hidden;
    @include media("<=1150px") {
        padding-top: 67px;
    }
        @include media("<=620px") {
             
        }
}

.content__inner {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
@include media("<=980px") {
    justify-content: center;
}
@include media("<=720px") {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
}
}

.breadcrumbs {
    display: flex;
    margin-bottom: 10px;
}

.breadcrumbs__item {
border-left: 1px solid #959595;

    &:first-of-type {
        border-left: none;
    }
}

.breadcrumbs__link {
  text-decoration: none;
  text-transform: uppercase;
  color: #C61D23;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 8px;
font-family: 'HeliosCond';
}

.main {
    max-width: 820px;
    width: 100%;
    @include media("<=980px") {
            max-width: 500px;
            
        }
}

.main__article {
    padding-bottom: 11px;
}

.article__head {
    display: flex;
    column-gap: 24px;
    margin-bottom: 20px;
    @include media("<=980px") {
      flex-direction: column;
    }
}

.article__image {
    flex: 0 1 100%;
    max-width: 400px;
    @include media("<=980px") {
          margin-bottom: 10px;
        }
    img {
        width: 100%;
        display: block;
    }
}

.article__head-content {
    flex: 0 1 100%;
    max-width: 373px;
    @include media("<=980px") {
        width: 100%;
        max-width: 500px;
    }
}

.article__suptitle {
    display: inline-block;
    color: #C61D23;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 8px;
    border: 1px #C61D23 solid;
    margin-bottom: 18px;
}

.article__title {
    font-size: 22px;
    text-transform: uppercase;
    line-height: 28px;
    margin-bottom: 12px;
    max-width: 325px;
    @include media("<=980px") {
            width: 100%;
            max-width: 405px;
        }
}

.article__dscr {
    font-size: 15px;
    line-height: 21px;
}

.article__list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.article__list-item {
    font-size: 15px;
    line-height: 21px;
}

.article__banner {
    margin-top: 15px;
   max-width: 820px;
   img {
    width: 100%;
    display: block;
   }
}

.article__list-title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 20px;
    span {
        padding-right: 10px;
    }
}

.article__subtext {
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.pagination {
    border-top: 1px solid #dedfe1;
        border-bottom: 1px solid #dedfe1;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        margin-bottom: 30px;
}

.pagination__item {
    padding: 10px 30px 16px;
    flex: 0 1 50%;
    font-size: 15px;
    line-height: 20px;
  &:last-of-type {
    border-left: 1px solid #dedfe1;
    text-align: right;
  }  
}

.pagination__title {
    margin-bottom: 9px;
    color: #C61D23;
    
    span {
        position: relative;
        top: 3px;
        width: 10px;
        height: 19px;
        display: inline-block;
            background: #C61D23;
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
    }
    .prev {
    mask: url(/images/prev.svg);
    margin-right: 7px;
    }
        .next {
            margin-left: 7px;
            mask: url(/images/next.svg);
        }
}

.pagination__text {
  font-weight: 700;
}

