.related__inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 20px;
    padding-top: 43px;
    padding-bottom: 33px;
    flex-wrap: wrap;
    max-width: 722px;
    margin: 0 auto;
    @include media("<=980px") {
        flex-direction: column;
        row-gap: 8px;
    }
}

.related__item {
    display: flex;
    flex-direction: column;
    flex: 0 1 31.4%;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: none;
    color: #333;
    @include media("<=1150px") {
        flex: 0 1 31%;
    }
          @include media("<=980px") {
              flex: 0 1 100%;
              width: 100%;
              flex-direction: row;
              column-gap: 16px;
          }
}

.related__image {
    max-width: 227px;
    width: 100%;
    margin-bottom: 8px;
    @include media("<=980px") {
        max-width: 120px;
        flex: 1 0 100%;
    }
    img {
        width: 100%;
        display: block;
    }
}

.related__date {
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-size: 12px;
    color: #959595;
    margin-bottom: 4px;
  &::before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-image: url(../images/time.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.related__title {

}

.more {
    padding-top: 43px;
    padding-bottom: 10px;
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
    border-bottom: 1px solid #dedfe1;
    @include media("<=500px") {
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
    }
}

.more__item {
    flex: 0 1 32%;
    font-size: 16px;
    line-height: 19px;
    color: #333;
    text-decoration: none;
    @include media("<=500px") {
        flex: 0 1 100%;
        max-width: 300px;
    }
    
}

.more__image {
    max-width: 262px;
    margin-bottom: 5px;
    @include media("<=500px") {
        max-width: 300px;
        width: 100%;
    }
    img {
        width: 100%;
        display: block;
    }
}

.more__title {
    font-weight: 700;
    &:hover {
            text-decoration: underline;
        }
}

.more__text {
  font-size: 14px;
  margin-bottom: 1px;
&:hover {
        text-decoration: underline;
    }
}

.branding {
    
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    @include media("<=1150px") {
        font-size: 9px;
    }
    p {
      font-weight: 700;
    box-sizing: border-box;
        border: 1px solid rgb(51, 51, 51);
        border-radius: 4px;
        padding: 5px 10px;
        @include media("<=1150px") {
            padding: 1px 4px;
        }
    }
}

.branding__text {
    color: #999999;
    span {
        font-weight: 700;
    }
}