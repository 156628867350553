.footer {
  background: rgb(79, 79, 79);
  padding: 40px 0 30px;
  @include media("<=980px") {
    padding: 0 0 40px;
  }
    
}

.footer__inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  @include media("<=980px") {
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
}


.footer__title {
  font-size: 16px;
  font-weight: 700;
  display: none;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    display: block;
  }
@include media("<=350px") {
  font-size: 16px;
}
}

.footer__link {
  display: inline-block;
  width: fit-content;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    -webkit-transition: border-color .3s ease 0s;
    transition: border-color .3s ease 0s;
  @include media("<=430px") {
    font-size: 14px;
    column-gap: 15px;
  }
@include media("<=350px") {
  font-size: 12px;
  justify-content: center;
}
  &:hover {
    border-bottom: 1px solid #fff;
  }
}

.footer__nav {
  display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 23px;
    width: 100%;
    padding-bottom: 21px;
    @include media("<=980px") {
     display: none;
    }
    @include media("<=430px") {
      
    }
}

.footer__main {
  padding: 40px 0 28px;
  position: relative;
  display: flex;
  width: 100%;
  border-top: 1px solid rgb(132, 132, 132);
  border-bottom: 1px solid rgb(132, 132, 132);
  @include media("<=980px") {
    flex-direction: column;
    align-items: center;
    border: none;
  }
}

.footer__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 60px;
  max-width: 390px;
  width: 100%;
  @include media("<=980px") {
     flex-direction: row;
     justify-content: center;
     max-width: 100%;
     padding-right: 0;
     padding-bottom: 60px;
  }
}

.footer__logo {
  position: relative;
  max-width: 252px;
  width: 100%;  
  margin-bottom: 30px;
  @include media("<=980px") {
    display: none;
  }
  @include media("<=430px") {
   
  }

  img {
    width: 100%;
    display: block;
  } 
}

.left__link {
  margin: 10px;
  max-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  column-gap: 12px;
  transition: all 0.3s linear;
  .icon {
      display: inline-block;
      background: #fff;
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      transition: all 0.3s ease;
    }
    .icon-tv {
      mask: url(../images/icon-tv.svg);
      width: 24px;
      height: 24px;
    }
    .icon-note {
      mask: url(../images/icon-note.svg);
      width: 28px;
      height: 23px;
    }
}

.left__link-red {
  background-color: rgb(198, 29, 35);
  &:hover {
    background-color: rgb(222, 32, 38);
  }
}

.left__link-grey {
  background-color: rgb(149, 149, 149);

  &:hover {
    background-color: rgb(186, 186, 186);
  }
}

.footer__column {
  display: flex;
    flex-direction: column;
  padding: 0 20px;
  max-width: 375px;
  width: 100%;
  @include media("<=980px") {
    max-width: 100%;
  border-top: 1px solid #848484;
    border-bottom: 1px solid #848484;
  }
}



.column__title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 28px;
  text-transform: uppercase;
  @include media("<=980px") {
    text-align: center;
    margin-bottom: 0;
    padding: 15px 0;
  }
}

.column__nav {
  display: flex;
    flex-direction: column;
    row-gap: 8px;
    @include media("<=980px") {
      display: none;
    }
}

.footer__social {
  padding: 15px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
  @include media("<=980px") {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 15px;
  }
  .social__title {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    @include media("<=980px") {
      text-align: center;
      width: 100%;
    }
  }
  .social__link {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: rgb(149, 149, 149);
      }
    }

        .icon-fb {
          mask: url(../images/icon-fb.svg);
          width: 7px;
          height: 14px;
        }
    
        .icon-telegram {
          mask: url(../images/icon-telegram.svg);
          width: 17px;
          height: 14px;
        }
    
        .icon-twitter {
          mask: url(../images/icon-twitter.svg);
          width: 17px;
          height: 14px;
        }
    
        .icon-youtube {
          mask: url(../images/icon-youtube.svg);
          width: 18px;
          height: 14px;
        }
    
        .icon-rss {
          mask: url(../images/icon-rss.svg);
          width: 14px;
          height: 14px;
        }
    
        .icon {
          display: inline-block;
          background: #fff;
          mask-size: contain;
          mask-position: center;
          mask-repeat: no-repeat;
          transition: all 0.3s ease;
        }
}

.copyright {
  font-size: 13px;
  padding: 0 20px;
  line-height: 19px;
}
