.comments__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 23px;
    border-bottom: 1px solid #dedfe1;
    margin-bottom: 24px;
}

.comments__count {
    font-weight: 700;
    font-size: 14px;
}

.comments__filter {
font-size: 14px;
margin-bottom: 33px;
span {
    font-size: 12px;
    font-weight: 700;
    border: 1px solid rgb(204, 208, 213);
        border-radius: 2px;
        background: rgb(245, 246, 247);
        padding: 4px 10px;
}
}

.comments__inner {
    padding: 0 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    margin-bottom: 34px;
}

.comments__active {
    width: 100%;
    display: flex;
    column-gap: 8px;

    textarea {
        width: 100%;
        max-width: 748px;
        resize: none;
        border: 1px solid rgb(211, 214, 219);
    }
}

.comments__item {
    width: 100%;
}

.comments__main {
  width: 100%;
    display: flex;
    column-gap: 8px;
    margin-bottom: 20px;
    &-response {
        margin-left: 54px;
    }
}

.comments__content {
    font-size: 14px;
    line-height: 19px;
}

.comments__name {
    color: #385898;
    font-weight: 700;
    margin-bottom: 4px;
}

.comments__react {
    display: flex;
    font-size: 12px;
    color: #90949C;
    .reaction {
        color: #385898;
        font-size: 12px;
        text-decoration: none;
    }
}

.comments__response {
    background: rgb(242, 241, 241);
    display: flex;
    column-gap: 8px;
    margin-left: 54px;
    margin-bottom: 3px;
}

.sponsor {
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
}

.sponsor__title {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(43, 57, 123);
}

.sponsor__content {
    max-width: 674px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4px 10px 8px;
    gap: 6px;
    background-image: url(../images/sponsor-bcg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sponsor__item {
    flex: 0 1 49%;
    img {
        width: 100%;
        display: block;
    }
}
