body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f2f2f2;
  color: #333333;
  position: relative;
  @include media("<=810px") {
    background-position-x: 67%;
    background-size: 380%;
  }
  @include media("<=430px") {
    background-size: 550%;
  }
  &.page {
    background-image: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.site-wrap {
  height: auto;
    padding: 0;
    max-width: 1150px;
    margin: 0 auto;
  background-color: #ffffff;  
}

.container {
  width: 100%;
  max-width: 1150px;
  padding: 0 5px;
  margin: 0 auto;
  @include media("<=1150px") {
    padding: 0 10px;
  }
}

.section__title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  color: #C61D23;
  font-weight: 700;
  line-height: 1.1;
  @include media("<=1080px") {
  }
  @include media("<=810px") {
    
    
  }
  @include media("<=430px") {
    
  }
}

.sidebar__title {
  color: #626262;
  font-weight: 700;
  width: 100%;
  box-sizing: border-box;
    border-bottom: 3px solid rgb(198, 29, 35);
    text-align: center;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 8px;
}

.banner {
  margin: 10px auto;
  display: block;
  max-width: 980px;
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}

//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=1150px") {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
    width: 42px;
    height: 42px;
    z-index: 200;
    background-color: #6f7072;
    cursor: pointer;
  }

  @include media("<=430px") {
    
  }

  @include media("<=1150px") {
    &.active {
      
      z-index: 200;
    }
    @include media("<=430px") {
      &.active {
        
      }
    }
    &:hover {
      background-color: #DE2027;
    }
  }

  .line {
    height: 2px;
    width: 16px;
    background-color: #fff;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      
    }
  }

  &.active {
    .line {
     
    }
  }
}
