.sidebar {
  flex: 0 1 100%;
  max-width: 300px;
  width: 100%;
  padding-bottom: 100px;
}

.live__link {
  background-color: #E2E2E2;
  color: #DE2027;
  font-size: 21px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  padding: 10px;
  max-width: 297px;
  width: 100%;
  transition: all 0.3s ease;
  box-shadow: 3px 3px 0px 0px rgb(193, 193, 193);

  .icon-tv {
      mask: url(../images/icon-tv.svg);
      width: 26px;
      height: 26px;
      background: #DE2027;
    }

  .icon {
      display: inline-block;
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      transition: all 0.3s ease;
    }
    &:hover {
      color: #fff;
      background-color: #DE2027;
      box-shadow: 3px 3px 0px 0px #b6191f;
    }
}

.live__link:hover .icon {
  background-color: #fff;
}

.sidebar__slider {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  img {
    width: 100%;
    display: block;
  }
}

.ribbon__title {
  position: absolute;
  top: 8px;
  left: -10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  width: 102px;
  height: 39px;
  display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../images/ribbon.png);
    padding-bottom: 9px;
    padding-right: 5px;
}

.sidebar__banner {
  margin: 0 0 20px;
  width: 100%;
  img {
    width: 100%;
  }
}

.sidebar__special {
  border-bottom: 2px solid #6f7073;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.special__title {
  width: 100%;
  display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
  background: rgba(0, 0, 0, 0.4);
  padding: 26px;
  margin-top: 20px;
}

.special__article {
  margin: 8px 0;
  display: flex;
  column-gap: 8px;
  font-size: 15px;
  line-height: 20px;
  &::before {
    content: '';
    position: relative;
    top: 6px;
    max-width: 8px;
    width: 100%;
    height: 8px;
    border-radius: 50%;
    background-color: #C61D23;
  }
}

.sidebar__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0;
  .prev {
    width: 20px;
    height: 20px;
    background: url(../images/prev.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 40px;
  }
    .next {
      width: 20px;
      height: 20px;
      background: url(../images/next.svg);
      background-repeat: no-repeat;
        background-position: center;
        margin-left: 40px;
    }
  .s-button {
    box-sizing: border-box;
      border: 1px solid rgb(123, 125, 127);
      border-radius: 50%;
      background: rgb(255, 255, 255);
      width: 14px;
        height: 14px;
    &-active {
      box-sizing: border-box;
        border: 1px solid rgb(198, 29, 35);
        background: rgb(198, 29, 35);
    }    
  }  
}

.choice {
  padding-bottom: 36px;
  padding-left: 5px;
  border-bottom: 2px solid #6f7073;
  margin-bottom: 20px;
}

.choice__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  border-top: 1px solid #dedfe1;
}

.choice__content {
  max-width: 160px;
  font-size: 15px;
  line-height: 19px;
}

.choice__date, .post__date {
  display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 12px;
    color: #959595;
    margin-bottom: 5px;
  
    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background-image: url(../images/time.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
}

.sidebar__post {
  margin-bottom: 20px;
  
  border-bottom: 2px solid #6f7073;
}

.post__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 8px;
  color: #424242;
  font-size: 15px;
  line-height: 19px;
  border-bottom: 1px solid rgb(222, 223, 225);
  padding: 18px 0 18px 5px;
}

.post__text {
  max-width: 160px;
  padding-bottom: 13px;
}

.events {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  padding: 20px 0 22px;
  border-bottom: 2px solid #6f7073;
  margin-bottom: 20px;
}

.events__image {
  margin-bottom: 7px;
  max-width: 137px;
  width: 100%;
  border: 3px solid transparent;
  transition: all 0.3s ease;
  img {
    width: 100%;
    display: block;
  }
}

.events__item {
  color: #424242;
    font-size: 15px;
    line-height: 19px;
    text-decoration: none;
    transition: all 0.3s ease;
    flex: 0 1 100%;
}

.events__item:hover .events__image {
 border: 3px solid #b6191f;
}

.events__item:hover .events__text {
  color: #b6191f;
}

.sidebar__block {
  border-bottom: 2px solid #6f7073;
}

.block__title {
  color: #c61d23;
  border-bottom: 1px solid #959595;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 5px;
  span {
    padding: 7px;
    border: 1px solid #959595;
    border-bottom: 1px solid #fff;
  }
}

.block__item {
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #dedfe1;
  column-gap: 13px;
  color: #424242;
  font-size: 15px;
  line-height: 19px;
}