.header {
  width: 100%;
  padding: 6px 0 0;
  position: relative;
  @include media("<=1150px") {
    border-bottom: 1px solid #c61d23;
      border-top: 1px solid #c61d23;
      padding: 0 10px;
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 20;
  }
    @include media("<=620px") {
      
    }
  
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20;
  @include media("<=1150px") {
    flex-wrap: wrap;
  }
}

.header__date {
  color: #797979;
  font-size: 13px;
  margin-left: auto;
  margin-right: 40px;
  @include media("<=1150px") {
    display: none;
  }
}

.mob__menu {
  display: none;
  @include media("<=1150px") {
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
  }
}

.mob__link {
  display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
    width: 42px;
    height: 42px;
    background-color: #6f7072;
    font-size: 18px;
      line-height: 18px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
    .icon-search {
        display: inline-block;
        background: #fff;
        mask: url(../images/icon-search.svg);
        width: 16px;
        height: 16px;
        mask-size: cover;
        transition: all 0.3s ease;
      }
        .icon-tvmob {
          display: inline-block;
          width: 18px;
          height: 24px;
          mask-size: cover;
          transition: all 0.3s ease;
          svg {
            width: 100%;
          }
        }
}

.header__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;
  row-gap: 6px;
  @include media("<=1150px") {
    display: none;
    max-width: 100%;
    flex: 0 1 100%;
    padding: 0 10px;
    background-color: #fff;
    row-gap: 4px;
    &.active {
        display: flex;
        position: fixed;
        top: 67px;
        left: 0;
    }
  }
}

.header__nav {
  flex: 0 1 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 34px;
  height: 100%;
  @include media("<=1150px") {
    flex-direction: column;
  }
  @include media("<=810px") {
    
   
  }
}

.header__link {
  font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s ease;
  height: 100%;
    min-height: 34px;
}

.header__nav-top {
  background: rgb(242, 241, 241);
  @include media("<=1150px") {
    margin: 10px 10px 5px;
    width: 98%;
  }

.header__link {
  color: #333;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 9px;
  height: 100%;
  min-height: 34px;
  padding: 0 16px;
  @include media("<=1150px") {
    display: none;
  }

  &:hover {
    color: #fff;
    background-color: #6f7072;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 24px;
    background-color: #c0c0c0;
  }
}

.header__link:last-of-type::after {
  display: none;
}



.header__link:hover .icon {
  background: #fff;
}



.icon:hover {
  background: #fff;
}

.header__social {
  height: 100%;
  display: flex;
  align-items: center;
}

.social__link {
  width: 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  @include media("<=1150px") {
    width: 50px;
    height: 50px;
  }
  &:hover {
    background: #df2027;
  }
}

.social__link:hover .icon {
  background: #fff;
}

.icon-tv {
  mask: url(../images/icon-tv.svg);
  width: 24px;
  height: 24px;
  background: #333;
}

.icon-fb {
  mask: url(../images/icon-fb.svg);
  width: 7px;
  height: 14px;
}

.icon-telegram {
  mask: url(../images/icon-telegram.svg);
  width: 17px;
  height: 14px;
}

.icon-twitter {
  mask: url(../images/icon-twitter.svg);
  width: 17px;
  height: 14px;
}

.icon-youtube {
  mask: url(../images/icon-youtube.svg);
  width: 18px;
  height: 14px;
}

.icon-rss {
  mask: url(../images/icon-rss.svg);
  width: 14px;
  height: 14px;
}

.icon {
  display: inline-block;
  background: #7D7F82;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  transition: all 0.3s ease;
}

}

.header__nav-mid {
  background-color: #6F7072;

  .header__link {
      color: #fff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 9px;
      height: 100%;
      font-size: 14px;
      min-height: 34px;
      padding: 0 11px;
      
  
      @include media("<=1150px") {
        border-top: 1px solid #919396;
        min-height: 48px;
        width: 100%;
      }
  
      &:hover {
        color: #fff;
        background-color: #ababab;
      }
  
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 1px;
        height: 100%;
        border-left: 1px solid #808285;
        border-right: 1px solid #626366;
        @include media("<=1150px") {
          border: none;
        }
      }
      &:last-of-type {
        margin-left: auto;
            @include media("<=1150px") {
              display: none;
            } 
      }
    }
        .icon {
          display: inline-block;
          background: #fff;
          mask: url(../images/icon-search.svg);
          width: 16px;
          height: 16px;
          mask-size: cover;
          transition: all 0.3s ease;
        }
}

.header__nav-bottom {
  background-color: #DF2027;

  .header__link {
      color: #fff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 9px;
      height: 100%;
      font-size: 14px;
      min-height: 34px;
      padding: 0 8px;
  
  
      @include media("<=1150px") {
          border-top: 1px solid #ef414d;
          min-height: 48px;
          width: 100%;
        }
  
      &:hover {
        color: #fff;
        background-color: #b01c21;
      }
  
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 1px;
        height: 100%;
        border-left: 1px solid #cb2128;
          border-right: 1px solid #ef414d;
        @include media("<=1150px") {
          border: none;
        }
      }
  
      
    }
}

.header__logo {
  max-width: 240px;
  width: 100%;
  padding: 0 6px;
  display: block;
  @include media("<=1150px") {
      max-width: 120px;
      padding: 5px 6px;
    }
    @include media("<=500px") {
      max-width: 54px;
      padding: 10px 6px;
    }
}

.logo {
  display: block;
  max-width: 240px;
  width: 100%;
  flex: 0 1 100%;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
    @include media("<=1150px") {
      max-width: 120px;
    }
    @include media("<=500px") {
      max-width: 42px;
    }
  img {
    width: 100%;
    display: block;
  }
}




